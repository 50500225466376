#coming-soon {
    overflow: hidden;
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background: url('../../images/snow-5369752_1920.jpg') no-repeat center fixed;
    /* background: url('../../images/mateo-vrbnjak-nCU4yq5xDEQ-unsplash.jpg') no-repeat center fixed; */
    background-size: cover;
    height: 100vh;
    width: 100%;
}
#coming-soon .main-container {
    position: relative;
    width: auto;
    height: auto;
}
#coming-soon .overlay-skew1 {
    /* background: rgba(0,0,0, 0.45); */
    background: url('../../images/watercolor-795161_1920.jpg') no-repeat;
    /* background: url('../../images/pascal-brandle-rGklzMk-58g-unsplash.jpg') no-repeat center; */
    background-size: cover;
    color: white;
    transform: skew(-20deg, 0deg);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: -49%;
    z-index: 2;
    /* box-shadow: 1px 0px 15px rgba(0,0,0,0.5); */
    transition: all 0.2s ease-out;
}
#coming-soon .left-section {
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 3;
    display: grid;
    place-items: center;
    transition: all 0.1s ease-out;
}
#coming-soon .wrapper {
    padding: 10%;
    color: white;
    text-align: center;
}
#coming-soon .main-header {
    font-size: 4rem;
    text-transform: uppercase;
    margin-top: 0;
    text-shadow: 1px 1px 20px black;
}
#coming-soon .sub-heading {
    font-size: 1.5rem;
    text-shadow: 1px 1px 15px black;
}

@media only screen and (max-width: 1024px) {
    #coming-soon .overlay-skew1 {
        transform: skew(0deg, 0deg);
        left: -100%;
        /* background: #80008082; */
    }
    #coming-soon .left-section {
        width: 100%;
        background-color: rgba(0,0,0, 0.4);
    }
    #coming-soon .main-header {
        font-size: 3rem;
    }
    #coming-soon .sub-heading {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    #coming-soon .overlay-skew1 {
        transform: skew(0deg, 0deg);
        left: -100%;
        /* background: #80008082; */
    }
    #coming-soon .left-section {
        width: 100%;
        background-color: rgba(0,0,0, 0.4);
    }
    #coming-soon .main-header {
        font-size: 2rem;
    }
    #coming-soon .sub-heading {
        font-size: 1.2rem;
    }
}