:root {
  --primary-color: #6fd530;
  --secondary-color: #15243D;
  --primary-color-1: #38BFF5;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--secondary-color);
  color: #fff;
  overflow-x: hidden;
  /* background: #27282e; */
}

.main {
  margin-top: 100px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 1280px;
}
.divider {
  border-color: var(--primary-color);
}

#header {
  background: rgba(0,0,0, 0.6);
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  z-index: 10;
}
#header-wrapper {
  position: relative;
  margin: 0;
  width: 100%;
}

.footer-wrapper {
  background: var(--primary-color);
  /* background: linear-gradient(145deg, var(--primary-color-1), var(--primary-color)); */
}


#left-section .p-tabview-nav {
  display: none;
}

.carousel-custom {
  position: relative;
  padding: 0 0.5rem;
}
.carousel-custom .p-carousel-prev{
  position: absolute;
  top: calc(50% - 48px);
  left: 2%;
  z-index: 10;
  background: rgba(0,0,0, 0.3) !important;
}
.carousel-custom .p-carousel-next{
  position: absolute;
  top: calc(50% - 48px);
  right: 2%;
  background: rgba(0,0,0, 0.3) !important;
}

.custom-card {
  color: var(--primary-color);
  /* text-shadow: 1px 1px 5px var(--primary-color); */
}
.custom-card .p-card-subtitle {
  color: var(--primary-color-1) !important;
  /* text-shadow: 0px 0 5px var(--primary-color-1); */
}
